<template>
  <div class="border-b border-gray-200 dark:border-gray-800 bg-white/75 dark:bg-gray-900/75">
    <div class="px-4 sm:px-6">
      <div class="flex items-center justify-between h-11">
        <div class="flex items-center space-x-3 sm:space-x-6">
          <UButton
            v-if="['xs', 'sm', 'md', 'lg'].includes($viewport.breakpoint.value)"
            :icon="isOpen ? 'i-heroicons-x-mark-20-solid' : 'i-heroicons-bars-3-20-solid'"
            variant="ghost"
            color="gray"
            @click="isOpen = !isOpen"
          />
          <Logo
            v-if="!['xs'].includes($viewport.breakpoint.value)"
            :model="model"
            @open="onMain"
          />
          <Menu :items="['xs', 'sm', 'md', 'lg'].includes($viewport.breakpoint.value) ? workPlan : menu" />
        </div>
        <div class="flex items-center space-x-3">
          <!-- <UsersOnline /> -->
          <UPopover>
            <template #default="{ open }">
              <UTooltip text="Сменить тему">
                <UButton
                  color="gray"
                  variant="ghost"
                  square
                  :class="[open && 'bg-gray-50 dark:bg-gray-800']"
                  @click="isOpen = false"
                >
                  <UIcon
                    name="i-heroicons-swatch-20-solid"
                    class="w-5 h-5 text-primary-500 dark:text-primary-400"
                  />
                </UButton>
              </UTooltip>
            </template>
            <template #panel>
              <ColorPicker />
            </template>
          </UPopover>
          <Profile
            @open="isOpen = false"
          />
        </div>
      </div>
    </div>

    <MobileMenu
      v-if="['xs', 'sm', 'md', 'lg'].includes($viewport.breakpoint.value) && isOpen"
      :items="menu"
      @close="isOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { PERMISSIONS } from '~/constants'

const $auth = useAuth()
const route = useRoute()
const router = useRouter()
const dayjs = useDayjs()
const appConfig = useAppConfig()
const { $viewport } = useNuxtApp()

const isOpen = ref<boolean>(false)

const model = computed(() => {
  let value = ''

  if ($auth.can(PERMISSIONS.cut.view)) {
    value = 'cut'
  } else if ($auth.can(PERMISSIONS.edge.view)) {
    value = 'edge'
  } else if ($auth.can(PERMISSIONS.additive.view)) {
    value = 'additive'
  } else if ($auth.can(PERMISSIONS.build.view)) {
    value = 'build'
  } else if ($auth.can(PERMISSIONS.pack.view)) {
    value = 'pack'
  }

  return value
})
const workPlan = computed(() => {
  return [
    {
      type: 'button',
      label: 'План работы',
      icon: 'i-heroicons-document-text-20-solid',
      to: {
        name: 'dashboard',
        query: {
          sectionId: model.value === 'cut' ? 1 : undefined,
          statusId: 1,
          page: 1,
          model: model.value,
          take: appConfig.DEFAULT_LIMIT
        }
      },
      current: route.name === 'dashboard',
      click: () => {
        isOpen.value = false
      }
    }
  ]
})
const menu = computed(() => {
  const selectedDate = route.query.selectedDate ? dayjs(route.query.selectedDate).format(appConfig.FORMAT_YYYY_MM_DD) : dayjs().format(appConfig.FORMAT_YYYY_MM_DD)

  return [
    {
      type: 'button',
      label: 'Сборка фурнитуры',
      icon: 'i-heroicons-document-text-20-solid',
      to: {
        name: 'build',
        query: {
          statusId: 1,
          page: 1,
          take: appConfig.DEFAULT_LIMIT
        }
      },
      permission: PERMISSIONS.build.view,
      current: route.name === 'build'
    },
    {
      type: 'button',
      label: 'Склад',
      icon: 'i-heroicons-document-text-20-solid',
      to: {
        name: 'stock',
        query: {
          stock: 1,
          selectedDate,
          page: 1,
          take: appConfig.DEFAULT_LIMIT
        }
      },
      permission: PERMISSIONS.stock.view,
      current: route.name === 'stock'
    },
    {
      type: 'dropdown',
      label: 'Аналитика',
      items: [
        [
          {
            type: 'button',
            label: 'Производство',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-manufacturer',
              query: {
                startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.manufacturer.analytic,
            current: route.name === 'analytics-manufacturer'
          },
          {
            type: 'button',
            label: 'Зарплата',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-pay',
              query: {
                selectedDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD)
              }
            },
            permission: PERMISSIONS.booker.pay,
            current: route.name === 'analytics-pay'
          },
          {
            type: 'button',
            label: 'Зарплата: с/ф',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-payBuild',
              query: {
                selectedDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD)
              }
            },
            permission: PERMISSIONS.booker.pay,
            current: route.name === 'analytics-payBuild'
          },
          {
            type: 'button',
            label: 'Склад',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-stock',
              query: {
                startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                stocks: 1,
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.stock.analytic,
            current: route.name === 'analytics-stock'
          },
          {
            type: 'button',
            label: 'Р/м: распил',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-materialConsumptionCut',
              query: {
                startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.manufacturer.analytic,
            current: route.name === 'analytics-materialConsumptionCut'
          },
          {
            type: 'button',
            label: 'Р/м: кромка',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-materialConsumptionEdge',
              query: {
                startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.manufacturer.analytic,
            current: route.name === 'analytics-materialConsumptionEdge'
          }
        ]
      ],
      permissions: [
        PERMISSIONS.manufacturer.analytic,
        PERMISSIONS.booker.pay,
        PERMISSIONS.stock.analytic,
        PERMISSIONS.manufacturer.analytic,
        PERMISSIONS.manufacturer.analytic
      ]
    },
    {
      type: 'dropdown',
      label: 'Продукция',
      items: [
        [
          {
            type: 'button',
            label: 'ЛДСП',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-products',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.product.view,
            current: route.name === 'dictionary-products'
          },
          {
            type: 'button',
            label: 'Короба',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-productsKoroba',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.product.view,
            current: route.name === 'dictionary-productsKoroba'
          },
          {
            type: 'button',
            label: 'Металл',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-productsMetal',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.product.view,
            current: route.name === 'dictionary-productsMetal'
          },
          {
            type: 'button',
            label: 'Расход материала',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-sheetFormats',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.dictionary.sheetFormat.view,
            current: route.name === 'dictionary-sheetFormats'
          }
        ]
      ],
      permissions: [
        PERMISSIONS.product.view,
        PERMISSIONS.dictionary.sheetFormat.view
      ]
    },
    {
      type: 'dropdown',
      label: 'Склад',
      items: [
        [
          {
            type: 'button',
            label: 'ЛДСП',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-stocks',
              query: {
                stock: 1,
                selectedDate,
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.stock.view,
            current: route.name === 'dictionary-stocks'
          },
          {
            type: 'button',
            label: 'Короба',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-stocksKoroba',
              query: {
                stock: 1,
                selectedDate,
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.stock.view,
            current: route.name === 'dictionary-stocksKoroba'
          },
          {
            type: 'button',
            label: 'Металл',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-stocksMetal',
              query: {
                stock: 1,
                selectedDate,
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.stock.view,
            current: route.name === 'dictionary-stocksMetal'
          }
        ]
      ],
      permissions: [
        PERMISSIONS.stock.view
      ]
    },
    {
      type: 'dropdown',
      label: 'Администрирование',
      items: [
        [
          {
            type: 'button',
            label: 'Сотрудники',
            icon: 'i-heroicons-users-20-solid',
            to: {
              name: 'users',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.user.view,
            current: route.name === 'users'
          },
          {
            type: 'button',
            label: 'Роли',
            icon: 'i-heroicons-shield-check-20-solid',
            to: {
              name: 'roles',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.role.view,
            current: route.name === 'roles'
          }
        ]
      ],
      permissions: [
        PERMISSIONS.user.view,
        PERMISSIONS.role.view
      ]
    },
    {
      type: 'button',
      icon: 'i-heroicons-view-columns-20-solid',
      label: 'Задачи',
      to: {
        name: 'tasks'
      },
      permission: PERMISSIONS.task.view,
      current: route.name === 'tasks'
    },
    {
      type: 'button',
      icon: 'i-heroicons-calculator-20-solid',
      label: 'Калькулятор',
      to: {
        name: 'calc'
      },
      permission: PERMISSIONS.calc.view,
      current: route.name === 'calc'
    },
    {
      type: 'button',
      icon: 'i-heroicons-document-text-20-solid',
      label: 'CRM',
      to: {
        name: 'crm'
      },
      permission: PERMISSIONS.crm.view,
      current: route.name === 'crm'
    }
  ]
})

const onMain = () => {
  if (!model.value) {
    return
  }

  router.push(workPlan.value[0].to)
  isOpen.value = false
}

watch(
  () => route.name,
  () => {
    if (isOpen.value) {
      isOpen.value = false
    }
  }
)
</script>
